import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PrivacyContent from "../components/content/privacy"
import Nav from "../components/nav"
import Footer from "../components/footer"

const PrivacyPage = () => (
  <>
    <SEO title="Privacy" />
    <Nav />
    <PrivacyContent />
    <Footer />
  </>
)

export default PrivacyPage
